<template>
  <a-modal :title="'过去病史'" :width="640" :visible="visible" @ok="ok"  @cancel="cancel" >
    <a-form :form="form" v-bind="{ labelCol:{xs:{span:24},sm:{span:7}},wrapperCol:{xs:{span:24 },sm:{span:13}}}">
			<a-form-item label="隐藏字段" style="display:none;">
			</a-form-item>
      <a-form-item label="名称">
        <a-input v-model="name" />
      </a-form-item>
			<!-- <a-form-item label="排序">
			  <a-input type="number" v-decorator="[ 'sort', { initialValue:0, rules: [{ required: true, message: '' }] }]" />
			</a-form-item> -->
    </a-form>
  </a-modal>
</template>

<script>
import pick from "lodash.pick"
import Img from "../../../../components/Img.vue"

// 表单字段
const fields = ["title", "sort"]

export default {
	components:{
		Img
	},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
			item:[],
      name:''
    };
  },
  created() {
    fields.forEach((v) => this.form.getFieldDecorator(v))
  },
  methods: {
    init(e) {
			let self = this
      self.form.setFieldsValue(pick(e, fields))
      self.visible = true;
			setTimeout(function(){
				self.$refs.img.init(e.img);
			},10)	
    },
    cancel() {
			this.form.resetFields();
      this.visible = false;
    },
    async ok() {
      console.log(this.name);
					this.$emit("ok", this.name)

    },
		img_ok(e){
			this.form.setFieldsValue({img:e})
		}
  },
}
</script>

<style>
img{ max-width:300px; }
</style>
