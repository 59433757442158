<template>
  <div class="qiqb-table">
    <div class="qiqb-table-item">
      <div class="table-operator">
        <div class="table-operator-title">{{ name }}</div>
        <a-button type="primary" @click="handleEdit({ id: 0 })">新建</a-button>
      </div>
      <div class="table-item">
        <a-table :columns="columns" :data-source="list" :pagination="page" size="middle" @change="more">
          <span slot="action" slot-scope="text, record">
            <!-- <a @click="handleEdit(record)">编辑</a> -->
            <a-divider type="vertical" v-if="record.pid != 0" />
            <a-popconfirm title="确认删除？" @confirm="del(record)" v-if="record.pid != 0">
              <a-icon slot="icon" type="question-circle-o" style="color: red" />
              <a>删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </div>
    </div>

    <!-- 提交表单 -->
    <create-form ref="createModal" @ok="add" />
  </div>
</template>

<script>
import CreateForm from "./modules/CreateForm";
import { binglist, add_bing, delete_bing } from "../../../api/index.js";

export default {
  components: {
    CreateForm,
  },
  methods: {
    handleEdit(record) {
      this.id = record.id;
      this.$refs.createModal.init(record);
    },
   async del(e) {
     console.log(e);
     let res=await delete_bing({id:e.id})
     if(res.status==200){
      this.getList()
     }
    },
    async getList(){
      let res=await binglist({type:'family_history '})
      this.list=res.data
    },
    async add(e){
      let res=await add_bing({name:e,type:'family_history'})
      this.$refs.createModal.cancel();
      this.getList()
    },
  },
  mounted() {
    this.getList()
  },
  data() {
    return {
      name: "",
      id: 0,
      queryParam: {},
      page: { pageSize: "10", total: 0, current: 0 },
      columns: [
        { key: "id", title: "编号", dataIndex: "id",  },
        { title: "名称", dataIndex: "name" },
        // { title: "排序", dataIndex: "index",  },
        // { title: "更新时间", dataIndex: "update_time",  },
        {
          title: "操作",
          key: "action",
          width: 120,
          scopedSlots: { customRender: "action" },
        },
      ],
      list:[],
      item: [],
    };
  },
  props: {
    msg: String,
  },
  watch: {
    $route: {
      handler: function (route) {
        this.name = route.name;
      },
      immediate: true,
    },
  },
};
</script>